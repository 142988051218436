/* eslint no-console:0 */
//import 'bootstrap';
import * as bootstrap from "bootstrap"
import 'daterangepicker'; // bootstrap dependency???
import fontawesome from '@fortawesome/fontawesome-free';
import {DataTable} from "simple-datatables";
if (document.querySelectorAll('.datatables').length) {
  const dataTable = new DataTable(".datatables", {"perPage": 100, "perPageSelect": false});
}

import datepicker from 'js-datepicker';
if (document.querySelectorAll('.datepicker').length) {
  document.querySelectorAll('.datepicker').forEach( element => {
      const picker = datepicker(element, {
        formatter: (input, date, instance) => {
          const value = date.toISOString().split('T')[0]; // YYYY-MM-DD
          input.value = value;
        },
        onSelect: (instance, date) => {
          instance.el.value = date.toISOString().split('T')[0]; // YYYY-MM-DD
          instance.el.dispatchEvent(new Event('change'));
        },
      });
    }
  );
}

require("@rails/ujs").start()

//import 'trix';

require.context('../images/', true, /\.(gif|jpg|png|svg)$/i);
import './src/application.scss';

import "./../controllers"

if (document.querySelectorAll('#new-exam-result').length) {
  document.querySelector('#exam_cert_type').addEventListener('change', function(event){
     if (this.value == 'EPA') {
       document.querySelectorAll('.epa').forEach( (i) => { i.classList.remove('d-none')});
       document.querySelectorAll('.non-epa').forEach( (i) => { i.classList.add('d-none')});
     }
     else if (this.value == '') {
       document.querySelectorAll('.epa').forEach( (i) => { i.classList.add('d-none')});
       document.querySelectorAll('.non-epa').forEach( (i) => { i.classList.add('d-none')});
     }
  });
  document.querySelector('form#new_proctor').addEventListener('ajax:success', function(event){
    document.getElementById('addProctor').classList.remove('show');
    document.querySelector('body').classList.remove('modal-open');
    document.querySelector('.modal-backdrop').remove();
  });
}

if (document.querySelector('#replacement-card')) {
  document.querySelector('#replacement-card').addEventListener("ajax:complete", function (e) {
    if (200 == e.detail[0].status) {
      this.querySelectorAll('span').forEach( (i) => { i.classList.toggle('show'); });
    }
  });
}

if (document.querySelector('#list-opt-out')) {
  document.querySelector('#list-opt-out').addEventListener("ajax:complete", function (e) {
    if (200 == e.detail[0].status) {
      this.querySelectorAll('span').forEach( (i) => { i.classList.toggle('show'); });
    }
  });
}

if (document.querySelectorAll('body.epa_report').length) {
  document.querySelector("#start_date").addEventListener('change', e => {
    document.querySelector(".csv-export input[name='start_date']").value = e.target.value;
  });
  document.querySelector("#end_date").addEventListener('change', e => {
    document.querySelector(".csv-export input[name='end_date']").value = e.target.value;
  });
}
